import { MathProblem, Problem } from "./MathProblem";
import { MathProblemUtils } from "./utils";

function generateAdditionProblem(): Problem {
  const firstNumber = MathProblemUtils.getRandomNumber(0, 12);
  const secondNumber = MathProblemUtils.getRandomNumber(0, 12);
  const solution = firstNumber + secondNumber;
  const options = [
    solution,
    ...MathProblemUtils.getRandomIncorrectSolutions(solution, 5, 2),
  ];
  return {
    question: `${firstNumber} + ${secondNumber} = ?`,
    options: MathProblemUtils.shuffleArray(options),
    solution,
  };
}

export function AdditionProblem() {
  return <MathProblem generateProblem={generateAdditionProblem} />;
}
