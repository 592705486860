import { useRef, useState, useLayoutEffect, RefObject } from "react";

export function useElementWidth(): [RefObject<HTMLDivElement>, number] {
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        setWidth(elementRef.current.offsetWidth);
      }
    };

    updateWidth(); // Call the updateWidth function when the component mounts

    return () => {
      // Cleanup function
    };
  }, []);

  return [elementRef, width];
}

export function useElementHeight(): [RefObject<HTMLDivElement>, number] {
  const elementRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    const updateHeight = () => {
      if (elementRef.current) {
        setHeight(elementRef.current.offsetHeight);
      }
    };

    updateHeight(); // Call the updateHeight function when the component mounts

    return () => {
      // Cleanup function
    };
  }, []);

  return [elementRef, height];
}
