import {
  Flex,
  Heading,
  Card,
  CardBody,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { stars as rawStars } from "./stars";
import { Star } from "./types";
import { useElementWidth } from "./useElementWidth";
import { StarDisplay } from "./StarDisplay";
import { StarSelector } from "./StarSizeComparison";
import { useSearchParams } from "react-router-dom";
import pluralize from "pluralize";

const stars: Star[] = [...rawStars].sort(
  (starA, starB) => starA.radius - starB.radius
);

function StarDetails(props: { star: Star }) {
  const { star } = props;
  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Type</Td>
              <Td>{star.type}</Td>
            </Tr>
            <Tr>
              <Td>
                R<sub>☉</sub>
              </Td>
              <Td>{star.radius}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {star.facts.map((fact) => (
        <Card mt={3} key={fact}>
          <CardBody>
            {star.singularPrefix === "a"
              ? pluralize(star.name)
              : star.singularPrefix === "the"
              ? "The " + star.name
              : star.name}
            {` `}
            {fact}
          </CardBody>
        </Card>
      ))}
    </>
  );
}

export function StarFacts() {
  const [elementRef, width] = useElementWidth();
  const maxWidth = width / 2;

  const [searchParams, setSearchParams] = useSearchParams();
  const star = stars.find((star) => star.name === searchParams.get("star"));

  return (
    <Flex ref={elementRef} flexDirection={"column"} p={5}>
      <Heading mb={5} fontSize={"4xl"}>
        Star Facts
      </Heading>
      <StarSelector
        marginTop={2}
        prefix=""
        placeholder="Select a star..."
        onChange={(selectedStar) =>
          setSearchParams({ star: selectedStar.name })
        }
        value={star}
      />
      {star ? (
        <StarDisplay
          key={star.name}
          star={star}
          maxWidth={maxWidth}
          largestStar={star}
          marginTop={5}
        />
      ) : null}
      {star ? <StarDetails star={star} /> : null}
    </Flex>
  );
}
