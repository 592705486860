import {
  Flex,
  Heading,
  Text,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { StarDisplay } from "./star-size-comparison/StarDisplay";
import { stars } from "./star-size-comparison/stars";
import { MathProblemUtils } from "./math/utils";
import { NumberIncrementor } from "./CountBy";
import { useNavigate } from "react-router-dom";

function HomeCard(props: {
  children: ReactNode;
  title: string;
  relativeUrl: string;
}) {
  const navigate = useNavigate();
  return (
    //mb={5} flexGrow={1} minWidth={300} maxWidth={500}>
    <Card onClick={() => navigate(props.relativeUrl)}>
      <CardHeader>{props.title}</CardHeader>
      <CardBody>{props.children}</CardBody>
    </Card>
  );
}

const step = MathProblemUtils.getRandomNumber(1, 10);
const stepCount = MathProblemUtils.getRandomNumber(1, 25);

export function Home() {
  const starsForDisplay = [
    stars[MathProblemUtils.getRandomNumber(0, stars.length - 1)],
    stars[MathProblemUtils.getRandomNumber(0, stars.length - 1)],
    stars[MathProblemUtils.getRandomNumber(0, stars.length - 1)],
    stars[MathProblemUtils.getRandomNumber(0, stars.length - 1)],
    stars[MathProblemUtils.getRandomNumber(0, stars.length - 1)],
    stars[MathProblemUtils.getRandomNumber(0, stars.length - 1)],
  ];

  // const [stepCount, setStepCount] = useState(
  //   MathProblemUtils.getRandomNumber(0, 25)
  // );
  return (
    <Flex
      flexDirection={"column"}
      p={5}
      overflowX={"hidden"}
      overflowY={"hidden"}
    >
      <Heading mb={5} fontSize={"4xl"}>
        Home
      </Heading>
      <Grid
        gap={5}
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
      >
        <GridItem>
          <HomeCard title="Count By" relativeUrl={"/count-by"}>
            <NumberIncrementor
              value={stepCount * step}
              // setStepCount((previousNumber) => previousNumber - 1)
              onDecrement={() => null}
              // setStepCount((previousNumber) => previousNumber + 1)
              onIncrement={() => null}
              label={`(${step} x ${stepCount})`}
            />
          </HomeCard>
        </GridItem>
        <GridItem>
          <HomeCard
            title="Stars sorted by size"
            relativeUrl={"/stars-sorted-by-size"}
          >
            <Flex alignItems={"center"} justifyContent={"space-around"}>
              {starsForDisplay.slice(0, 4).map((star, index) => (
                <StarDisplay
                  star={star}
                  marginTop={0}
                  maxWidth={index * 20 + 10}
                  largestStar={star}
                  shouldShowLabel={false}
                />
              ))}
            </Flex>
          </HomeCard>
        </GridItem>
        <GridItem>
          <HomeCard
            title="How many could fit?"
            relativeUrl={"/star-size-comparison"}
          >
            <Flex
              flexDirection="column"
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              {starsForDisplay.slice(4, 6).map((star, index) => (
                <StarDisplay
                  star={star}
                  marginTop={index === 0 ? 0 : 5}
                  maxWidth={index * 50 + 30}
                  largestStar={star}
                  shouldShowLabel={false}
                />
              ))}
            </Flex>
          </HomeCard>
        </GridItem>
      </Grid>
    </Flex>
  );
}
