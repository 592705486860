export class MathProblemUtils {
  public static getRandomNumber(min: number, max: number): number {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  public static getRandomIncorrectSolutions(
    solution: number,
    range: number,
    numToGenerate: number,
    generatedSolutions: number[] = []
  ): number[] {
    // If the target is low (bottom of range will be negative), add more options in the upper range
    const targetIsLowNumber = solution - range < 0;
    const randomNumber = this.getRandomNumber(
      targetIsLowNumber ? 0 : solution - range,
      solution + range + (targetIsLowNumber ? range - solution : 0)
    );
    // If the number matches the target or another generated solution, try again
    if (
      randomNumber === solution ||
      generatedSolutions.includes(randomNumber)
    ) {
      return this.getRandomIncorrectSolutions(
        solution,
        range,
        numToGenerate,
        generatedSolutions
      );
    }
    // If the target number of solutions has not been reached, continue
    if (generatedSolutions.length + 1 !== numToGenerate) {
      return this.getRandomIncorrectSolutions(solution, range, numToGenerate, [
        ...generatedSolutions,
        randomNumber,
      ]);
    }
    return [...generatedSolutions, randomNumber];
  }

  public static shuffleArray(array: any[]) {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }
}
