import { Box, Flex, Text, keyframes } from "@chakra-ui/react";
import { Star } from "./types";
import { temperatureToHexColor } from "./bvToRgb";
import chroma from "chroma-js";
import { MathProblemUtils } from "../math/utils";
import React from "react";
import { stars } from "./stars";

const GlowAnimation: Record<
  "SUBTLE" | "BLURRY",
  (glowColor: string) => ReturnType<typeof keyframes>
> = {
  SUBTLE: (glowColor: string) => keyframes`
    0% {
      box-shadow: 0 0 5px ${glowColor}, 0 0 10px ${glowColor};
    }
    50% {
      box-shadow: 0 0 10px ${glowColor}, 0 0 15px ${glowColor};
    }
    100% {
      box-shadow: 0 0 5px ${glowColor}, 0 0 10px ${glowColor};
    }
  `,
  BLURRY: (glowColor: string) => keyframes`
    0% {
      box-shadow: 0 0 5px ${glowColor}, 0 0 10px ${glowColor}, 0 0 15px ${glowColor}, 0 0 20px ${glowColor}, 0 0 25px ${glowColor}, 0 0 30px ${glowColor};
    }
    50% {
      box-shadow: 0 0 10px ${glowColor}, 0 0 15px ${glowColor}, 0 0 20px ${glowColor}, 0 0 25px ${glowColor}, 0 0 30px ${glowColor}, 0 0 35px ${glowColor};
    }
    100% {
      box-shadow: 0 0 5px ${glowColor}, 0 0 10px ${glowColor}, 0 0 15px ${glowColor}, 0 0 20px ${glowColor}, 0 0 25px ${glowColor}, 0 0 30px ${glowColor};
    }
  `,
};

interface GlowingCircleProps {
  width: number;
  glowColor?: string;
  innerColor: string;
  glowAnimation: typeof GlowAnimation[keyof typeof GlowAnimation];
}

const GlowingCircle = (props: GlowingCircleProps) => {
  const {
    width,
    innerColor,
    glowColor = chroma(innerColor).brighten(1).hex(),
    glowAnimation,
  } = props;

  const randomDuration = MathProblemUtils.getRandomNumber(20, 50) / 10;

  const circleStyles = {
    width: `${width}px`,
    height: `${width}px`,
    borderRadius: "50%",
    backgroundColor: innerColor,
    boxShadow: `0 0 5px ${glowColor}, 0 0 10px ${glowColor}`,
    animation: `${glowAnimation(glowColor)} ${randomDuration}s infinite`,
  };

  return <Box sx={circleStyles} />;
};

const GlowingCircleWithRotatingGradient = (props: GlowingCircleProps) => {
  const { width, innerColor, glowColor = innerColor, glowAnimation } = props;

  const similarColor = chroma(innerColor).brighten(1).hex();

  const randomDuration = MathProblemUtils.getRandomNumber(300, 500) / 100;
  const randomDurationLong = MathProblemUtils.getRandomNumber(1500, 2500) / 100;

  const rotationAnimation = keyframes`
    0% { 
      transform: rotate(0deg); 
      background: radial-gradient(circle at 50% 0%, ${similarColor}, ${innerColor} 70%, ${glowColor});
    }
    50% { 
      transform: rotate(180deg); 
      background: radial-gradient(circle at 50% 0%, ${similarColor}, ${innerColor} 70%, ${glowColor});
    }
    100% { 
      transform: rotate(360deg); 
      background: radial-gradient(circle at 50% 0%, ${similarColor}, ${innerColor} 70%, ${glowColor});
    }
  `;

  const styles = {
    width: `${width}px`,
    height: `${width}px`,
    borderRadius: "50%",
    boxShadow: `0 0 5px ${glowColor}, 0 0 10px ${glowColor}, 0 0 15px ${glowColor}, 0 0 20px ${glowColor}, 0 0 25px ${glowColor}, 0 0 30px ${glowColor}`,
    animation: `${glowAnimation(
      glowColor
    )} ${randomDuration}s infinite, ${rotationAnimation} ${randomDurationLong}s linear infinite`,
  };

  return <Box sx={styles} />;
};

const StarVariant: Record<
  "GLOWING" | "GLOWING_WITH_ROTATING_GRADIENT",
  React.FC<GlowingCircleProps>
> = {
  GLOWING: GlowingCircle,
  GLOWING_WITH_ROTATING_GRADIENT: GlowingCircleWithRotatingGradient,
};

export function StarDisplay(props: {
  star: Star;
  maxWidth: number;
  largestStar?: Star;
  marginTop?: number;
  marginHorizontal?: number;
  glowType?: keyof typeof GlowAnimation;
  variant?: keyof typeof StarVariant;
  shouldShowLabel?: boolean;
}) {
  const {
    star,
    maxWidth,
    largestStar = stars[stars.length - 1],
    glowType = "SUBTLE",
    marginTop = 20,
    variant = "GLOWING_WITH_ROTATING_GRADIENT",
    shouldShowLabel = true,
  } = props;

  const scaleRatio = maxWidth / largestStar.radius;

  const color = star.bvColorIndex ? temperatureToHexColor(star.temperature) : undefined;

  const AnimatedStar = StarVariant[variant];

  return (
    <Flex flexDirection="column" alignItems={"center"} marginTop={marginTop}>
      <AnimatedStar
        width={scaleRatio * star.radius}
        innerColor={color ?? "white"}
        glowAnimation={GlowAnimation[glowType]}
      />
      {shouldShowLabel ? <Text marginTop={3}>{star.name}</Text> : null}
    </Flex>
  );
}
