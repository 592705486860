// https://codepen.io/blaketarter/pen/EjxRMX
// this code is a lot more messy than I usually like, but meh it works.

// color index to temperature in kelvin
function bvToT(bv: number) {
  let t;

  // make sure bv is within its bounds [-0.4, 2] otherwise the math doesnt work
  if (bv < -0.4) {
    bv = -0.4;
  } else if (bv > 2) {
    bv = 2;
  }

  // found it online at http://www.wikiwand.com/en/Color_index
  t = 4600 * (1 / (0.92 * bv + 1.7) + 1 / (0.92 * bv + 0.62));

  // console.log('t: ' + t);

  return t;
}

// temperature to CIE xyY Colorspace, assume Y is 1
function tToXyy(t: number) {
  let x,
    y,
    Y = 1; // Y is the luminance, I just assume full luminance for sanity

  // approximation of CIE xyY (http://www.wikiwand.com/en/CIE_1931_color_space) using https://en.wikipedia.org/wiki/Planckian_locus
  if (t >= 1667 && t <= 4000) {
    x =
      -0.2661239 * (Math.pow(10, 9) / Math.pow(t, 3)) -
      -0.234358 * (Math.pow(10, 6) / Math.pow(t, 2)) +
      0.8776956 * (Math.pow(10, 3) / t) +
      0.17991;
  } else if (t >= 4000 && t <= 25000) {
    x =
      -3.0258469 * (Math.pow(10, 9) / Math.pow(t, 3)) +
      2.1070379 * (Math.pow(10, 6) / Math.pow(t, 2)) +
      0.2226347 * (Math.pow(10, 3) / t) +
      0.24039;
  }

  if (t >= 1667 && t <= 2222) {
    y =
      //@ts-ignore
      -1.1063814 * Math.pow(x, 3) -
      //@ts-ignore
      1.3481102 * Math.pow(x, 2) +
      //@ts-ignore
      2.18555832 * x -
      0.20219683;
  } else if (t >= 2222 && t <= 4000) {
    y =
      //@ts-ignore
      -0.9549476 * Math.pow(x, 3) -
      //@ts-ignore
      1.37418593 * Math.pow(x, 2) +
      //@ts-ignore
      2.09137015 * x -
      0.16748867;
  } else if (t >= 4000 && t <= 25000) {
    y =
      //@ts-ignore
      3.081758 * Math.pow(x, 3) -
      //@ts-ignore
      5.8733867 * Math.pow(x, 2) +
      //@ts-ignore
      3.75112997 * x -
      0.37001483;
  }

  // console.log('xyY: ' + [x, y, Y]);

  return [x, y, Y];
}

// xyY Color space to XYZ, prepping for conversion to linear RGB
function xyYToXyz(xyY: any[]) {
  let X,
    Y,
    Z,
    x = xyY[0],
    y = xyY[1];

  // X and Z tristimulus values calculated using https://en.wikipedia.org/wiki/CIE_1931_color_space?oldformat=true#CIE_xy_chromaticity_diagram_and_the_CIE_xyY_color_space
  Y = xyY[2];
  X = y === 0 ? 0 : (x * Y) / y;
  Z = y === 0 ? 0 : ((1 - x - y) * Y) / y;

  // console.log('XYZ: ' + [X, Y, Z]);

  return [X, Y, Z];
}

//XYZ color space to linear RGB, finally a format I recognize
function xyzToRgb(xyz: any[]) {
  let r,
    g,
    b,
    x = xyz[0],
    y = xyz[1],
    z = xyz[2];

  // using matrix from https://www.cs.rit.edu/~ncs/color/t_convert.html#RGB%20to%20XYZ%20&%20XYZ%20to%20RGB
  r = 3.2406 * x + -1.5372 * y + -0.4986 * z;

  g = -0.9689 * x + 1.8758 * y + 0.0415 * z;

  b = 0.0557 * x + -0.204 * y + 1.057 * z;

  // make sure the values didnt overflow
  r = r > 1 ? 1 : r;
  g = g > 1 ? 1 : g;
  b = b > 1 ? 1 : b;

  // console.log('rgb: ' + [r, g, b]);

  return [r, g, b];
}

// Im supposed to gamma correct and convert to sRGB but right now it breaks things so TODO: fix this..
function gammaCorrect(rgb: any[]) {
  let a = 0.055,
    gamma = 2.2,
    R,
    G,
    B,
    r = rgb[0],
    g = rgb[1],
    b = rgb[2];

  // using https://en.wikipedia.org/wiki/SRGB?oldformat=true#The_forward_transformation_.28CIE_xyY_or_CIE_XYZ_to_sRGB.29
  /*R = (r <= 0.0031308) ? 12.92 * r : ((1 + r) * Math.pow(r, 1 / 2.2)) - a;
  G = (g <= 0.0031308) ? 12.92 * g : ((1 + g) * Math.pow(g, 1 / 2.2)) - a;
  B = (b <= 0.0031308) ? 12.92 * b : ((1 + b) * Math.pow(b, 1 / 2.2)) - a;*/

  /*R = Math.pow(r, 1 / gamma);
  G = Math.pow(g, 1 / gamma);
  B = Math.pow(b, 1 / gamma);*/

  R = r;
  G = g / 1.05; // idk but i messed up somewhere and this makes it look better
  B = b;

  R = R > 1 ? 1 : R;
  G = G > 1 ? 1 : G;
  B = B > 1 ? 1 : B;

  // turn the 0-1 rgb value to 0-255
  return [Math.round(R * 255), Math.round(G * 255), Math.round(B * 255)];
}

// rgb to hex is cake
function rgbToHex(rgb: { toString: (arg0: number) => string }[]) {
  return "#" + rgb[0].toString(16) + rgb[1].toString(16) + rgb[2].toString(16);
}

// now put it all together!
export function bvToRgb(bv: any) {
  let t, xyY, xyz, rgb, crgb;

  t = bvToT(bv);

  xyY = tToXyy(t);

  xyz = xyYToXyz(xyY);

  rgb = xyzToRgb(xyz);

  crgb = gammaCorrect(rgb);

  return crgb;
}

export function bvToHex(bvColorIndex: number) {
  const rgb = bvToRgb(bvColorIndex);
  return "#" + rgb[0].toString(16) + rgb[1].toString(16) + rgb[2].toString(16);
}


// From claude https://claude.ai/chat/60ad1486-1a17-4923-a2a0-ad3a82565f31

export function bvToHexColor(bvIndex: number): string {
  // Clamp the B-V index to a reasonable range
  const clampedBV = Math.max(-0.4, Math.min(2.0, bvIndex));

  // Map B-V index to RGB values
  let r: number, g: number, b: number;

  if (clampedBV < 0) {
    // Blue to white stars (pure blue enhanced)
    const t = (clampedBV + 0.4) / 0.4;
    r = 50 + 205 * t;
    g = 150 + 105 * t;
    b = 255;
  } else if (clampedBV < 0.5) {
    // White to yellow stars
    const t = clampedBV / 0.5;
    r = 255;
    g = 255;
    b = 255 - 55 * t;
  } else if (clampedBV < 1.5) {
    // Yellow to orange stars
    const t = (clampedBV - 0.5) / 1.0;
    r = 255;
    g = 255 - 100 * t;
    b = 200 - 150 * t;
  } else {
    // Orange to red stars
    const t = (clampedBV - 1.5) / 0.5;
    r = 255;
    g = 155 - 55 * t;
    b = 50 - 50 * t;
  }

  // Convert RGB to hex
  const toHex = (c: number) => Math.round(c).toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function temperatureToHexColor(temp: number): string {
  let r: number, g: number, b: number;

  if (temp <= 3500) {
    // Red stars
    r = 255;
    g = 56;
    b = 0;
  } else if (temp <= 5000) {
    // Orange stars
    r = 255;
    g = 142;
    b = 45;
  } else if (temp <= 6000) {
    // Yellow stars
    r = 255;
    g = 209;
    b = 163;
  } else if (temp <= 7500) {
    // White stars with a hint of blue
    r = 255;
    g = 244;
    b = 234;
  } else if (temp <= 10000) {
    // Blue-white stars
    r = 201;
    g = 226;
    b = 255;
  } else {
    // Blue stars
    r = 162;
    g = 191;
    b = 255;
  }

  // Convert RGB to hex
  const toHex = (c: number) => Math.round(c).toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}