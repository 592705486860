import { MathProblem, Problem } from "./MathProblem";
import { MathProblemUtils } from "./utils";

function generateSubtractionProblem(): Problem {
  const firstNumber = MathProblemUtils.getRandomNumber(0, 20);
  const secondNumber = MathProblemUtils.getRandomNumber(0, 20);
  const largerNumber = Math.max(firstNumber, secondNumber);
  const smallerNumber = Math.min(firstNumber, secondNumber);
  const solution = largerNumber - smallerNumber;
  const options = [
    solution,
    ...MathProblemUtils.getRandomIncorrectSolutions(solution, 5, 2),
  ];
  return {
    question: `${largerNumber} - ${smallerNumber} = ?`,
    options: MathProblemUtils.shuffleArray(options),
    solution,
  };
}

export function SubtractionProblem() {
  return <MathProblem generateProblem={generateSubtractionProblem} />;
}
