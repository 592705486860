import React, { useState } from "react";
import { Box, Flex, Text, Button, useToast } from "@chakra-ui/react";
import { vibrateThenDo } from "../../utils";

export interface Problem {
  question: string;
  options: number[];
  solution: number;
}

const COMMON_TOAST_OPTIONS = {
  isClosable: false,
  position: "bottom" as const,
  duration: 1500,
};

enum Status {
  SUCCESS = "success",
  FAILURE = "failure",
  NEUTRAL = "neutral",
}

export function MathProblem(props: { generateProblem: () => Problem }) {
  const toast = useToast();
  const [problem, setProblem] = useState<Problem>(props.generateProblem);
  const [status, setStatus] = useState<Status>(Status.NEUTRAL);

  const checkSolution = (selectedAnswer: number) => {
    if (selectedAnswer === problem.solution) {
      // Success!
      setStatus(Status.SUCCESS);
      toast({
        ...COMMON_TOAST_OPTIONS,
        title: "Correct!",
        status: "success",
        onCloseComplete: () => {
          setStatus(Status.NEUTRAL);
          setProblem(props.generateProblem());
        },
      });
    } else {
      // Try again
      setStatus(Status.FAILURE);
      toast({
        ...COMMON_TOAST_OPTIONS,
        title: "Sorry, try again!",
        status: "error",
        onCloseComplete: () => {
          setStatus((status) =>
            status === Status.FAILURE ? Status.NEUTRAL : status
          );
        },
      });
    }
  };

  let backgroundStyle = {};
  if (status === Status.SUCCESS) {
    backgroundStyle = { backgroundColor: "rgba(0,180,0,.2)" };
  } else if (status === Status.FAILURE) {
    backgroundStyle = { backgroundColor: "rgba(180,0,0,.2)" };
  }

  return (
    <Flex
      width={"100%"}
      textAlign="center"
      p={10}
      height={"100vh"}
      flexDirection={"column"}
      justifyContent="center"
      style={backgroundStyle}
    >
      <Box>
        <Text fontSize={"6xl"} style={{ userSelect: "none" }} mb={10}>
          {problem.question}
        </Text>
      </Box>
      <Flex justifyContent="space-around">
        {problem.options.map((option, index) => (
          <Button
            key={index}
            size="lg"
            onTouchStart={() => vibrateThenDo(() => checkSolution(option))}
          >
            {option}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
}
