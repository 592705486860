import { MathProblem, Problem } from "./MathProblem";
import { MathProblemUtils } from "./utils";

function generateMultiplicationProblem(): Problem {
  const firstNumber = MathProblemUtils.getRandomNumber(0, 12);
  const secondNumber = MathProblemUtils.getRandomNumber(0, 12);
  const solution = firstNumber * secondNumber;
  const options = [
    solution,
    ...MathProblemUtils.getRandomIncorrectSolutions(solution, 10, 2),
  ];
  return {
    question: `${firstNumber} x ${secondNumber} = ?`,
    options: MathProblemUtils.shuffleArray(options),
    solution,
  };
}

export function MultiplicationProblem() {
  return <MathProblem generateProblem={generateMultiplicationProblem} />;
}
