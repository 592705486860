import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

export const AppMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <Portal>
        <MenuList>
          <MenuOptionGroup title="Counting" value={pathname} type="radio">
            <MenuItemOption
              value="/count-by"
              onClick={() => navigate("/count-by")}
            >
              Count By
            </MenuItemOption>
            <MenuDivider />
          </MenuOptionGroup>
          <MenuOptionGroup title="Math" value={pathname} type="radio">
            <MenuItemOption
              value="/addition"
              onClick={() => navigate("/addition")}
            >
              Addition
            </MenuItemOption>
            <MenuItemOption
              value="/subtraction"
              onClick={() => navigate("/subtraction")}
            >
              Subtraction
            </MenuItemOption>
            <MenuItemOption
              value="/multiplication"
              onClick={() => navigate("/multiplication")}
            >
              Multiplication
            </MenuItemOption>
            <MenuItemOption
              value="/division"
              onClick={() => navigate("/division")}
            >
              Division
            </MenuItemOption>
          </MenuOptionGroup>
          <MenuOptionGroup
            title="Star Size Comparison"
            value={pathname}
            type="radio"
          >
            <MenuItemOption
              value="/star-size-comparison"
              onClick={() => navigate("/star-size-comparison")}
            >
              How many could fit?
            </MenuItemOption>
            <MenuItemOption
              value="/stars-sorted-by-size"
              onClick={() => navigate("/stars-sorted-by-size")}
            >
              Stars sorted by size
            </MenuItemOption>
            <MenuItemOption
              value="/star-facts"
              onClick={() => navigate("/star-facts")}
            >
              Star facts
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};
