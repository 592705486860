import {
  Flex,
  Heading,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { stars as rawStars } from "./stars";
import { Star } from "./types";
import { useElementWidth } from "./useElementWidth";
import { StarDisplay } from "./StarDisplay";
import { useState } from "react";
import { StarSelector } from "./StarSizeComparison";
import { CountBySwitch } from "../CountBy";

const stars: Star[] = [...rawStars].sort(
  (starA, starB) => starA.radius - starB.radius
);

function ScaleSlider(props: { onChange: (value: number) => void }) {
  const { onChange } = props;

  return (
    <Slider
      flex={1}
      aria-label="step-slider"
      defaultValue={100}
      min={5}
      max={100 * 2}
      onChange={onChange}
      orientation="horizontal"
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxSize={8} backgroundColor={"lightblue"} />
    </Slider>
  );
}

enum Orientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

const HumanReadableOrientationMap = {
  [Orientation.HORIZONTAL]: "Horizontal",
  [Orientation.VERTICAL]: "Vertical",
};

export function SortedStars() {
  const [elementRef, width] = useElementWidth();
  const [scalePercent, setScalePercent] = useState(100);
  const [largestStar, setLargestStar] = useState<Star>(
    stars.find((star) => star.name === "Stephenson 2-18")!
  );
  const starsToDisplay = stars.filter(
    (star) => star.radius <= largestStar.radius
  );
  const [orientation, setOrientation] = useState(Orientation.VERTICAL);
  const maxWidth = width * (scalePercent / 100) - 20;

  return (
    <Flex
      ref={elementRef}
      flexDirection={"column"}
      p={5}
      overflowX={"hidden"}
      overflowY={"hidden"}
    >
      <Heading mb={5} fontSize={"4xl"}>
        Stars In Order
      </Heading>
      <Flex width="100%" justifyContent={"center"}>
        <Card flex={1} maxWidth={700}>
          <CardBody>
            <Flex flexDirection={"column"} paddingLeft={5} paddingRight={5}>
              <Text mb={5}>Star Scale</Text>
              <ScaleSlider onChange={(percent) => setScalePercent(percent)} />
            </Flex>
            <Flex justifyContent={"space-evenly"}>
              <Flex flexDirection={"column"}>
                <Text mt={5}>Largest Star</Text>
                <StarSelector
                  marginTop={2}
                  prefix=""
                  placeholder=""
                  onChange={(star) => setLargestStar(star)}
                  value={largestStar}
                />
              </Flex>

              <Flex flexDirection={"column"}>
                <Text mt={5}>Orientation</Text>
                <CountBySwitch
                  id="orientation"
                  label={HumanReadableOrientationMap[orientation]}
                  value={orientation === Orientation.HORIZONTAL}
                  onToggle={() =>
                    setOrientation((currentOrientation) =>
                      currentOrientation === Orientation.VERTICAL
                        ? Orientation.HORIZONTAL
                        : Orientation.VERTICAL
                    )
                  }
                />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <Flex
        flexDirection={orientation === Orientation.VERTICAL ? "column" : "row"}
        flexWrap={orientation === Orientation.VERTICAL ? undefined : "wrap"}
        justifyContent={
          orientation === Orientation.VERTICAL ? undefined : "space-evenly"
        }
        alignItems={
          orientation === Orientation.VERTICAL ? undefined : "baseline"
        }
      >
        {starsToDisplay.map((star) => (
          <StarDisplay
            key={star.name}
            star={star}
            maxWidth={maxWidth}
            largestStar={largestStar}
            marginTop={orientation === Orientation.VERTICAL ? undefined : 5}
          />
        ))}
      </Flex>
    </Flex>
  );
}
