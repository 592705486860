import React, { useEffect, useState } from "react";
import { Flex, Text, Select } from "@chakra-ui/react";
import pluralize from "pluralize";
import converter from "number-to-words";
import "animate.css";

import { Star } from "./types";
import { StarDisplay } from "./StarDisplay";
import { useElementHeight } from "./useElementWidth";
import { stars } from "./stars";
import { useSearchParams } from "react-router-dom";
import { MathProblemUtils } from "../math/utils";

function howManyFit(smallerStar: Star, largerStar: Star): number {
  const smallerStarVolume = (4 / 3) * Math.PI * Math.pow(smallerStar.radius, 3);
  const largerStarVolume = (4 / 3) * Math.PI * Math.pow(largerStar.radius, 3);
  const fitCount = largerStarVolume / smallerStarVolume;
  return Math.floor(fitCount);
}

function getBiggerStar(star1: Star | undefined, star2: Star | undefined) {
  if (star1 && star2) {
    return star1.radius > star2.radius ? star1 : star2;
  }
  return star1 ?? star2;
}

interface StarSelectorProps {
  prefix: string;
  suffix?: string;
  placeholder: string;
  onChange: (star: Star) => void;
  value?: Star;
  pluralize?: boolean;
  marginTop?: number;
}

export function StarSelector(props: StarSelectorProps) {
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStarName = event.currentTarget.value;
    const star = stars.find((star) => star.name === selectedStarName)!;
    props.onChange(star);
  };

  return (
    <Flex flexDirection={"column"} marginTop={props.marginTop ?? 5}>
      <Flex alignItems={"center"}>
        <Text fontSize={"2xl"} marginRight={2} style={{ whiteSpace: "nowrap" }}>
          {props.prefix}
        </Text>
        <Select
          placeholder={props.placeholder}
          onChange={onChange}
          value={props.value?.name}
        >
          {stars.map((star) => {
            let starDisplayName = star.name;
            // i.e. "How many ____s"
            if (props.pluralize) {
              starDisplayName = pluralize(star.name);
            } else if (star.singularPrefix) {
              // e.g. "The" Sun
              starDisplayName = `${star.singularPrefix} ${star.name}`;
            }
            return (
              <option key={star.name} value={star.name}>
                {starDisplayName}
              </option>
            );
          })}
        </Select>
        {props.suffix ? (
          <Text
            fontSize={"2xl"}
            marginLeft={2}
            style={{ whiteSpace: "nowrap" }}
          >
            {props.suffix}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
}

const getNumberInWords = (number: number): string => {
  const funnyDefaults = [
    `So much star, so much number`,
    `I'm not even going to try saying that one...`,
    `Um...a lot?`,
    `My god, it's full of stars!`,
  ];
  let words =
    funnyDefaults[
      MathProblemUtils.getRandomNumber(0, funnyDefaults.length - 1)
    ];
  try {
    words = converter.toWords(number);
  } catch (e) {
    console.warn(
      `There was an error converting ${number} to words. See below:`
    );
    console.error(e);
  }
  return words;
};

// TODO: update this once trivia is accessible securely (SSL)
const TRIVIA_ENABLED = false;

function StarComparisonResult(props: {
  smallerStar: Star;
  biggerStar: Star;
  shouldShowCalculating: boolean;
  numberOfStarsWhichFit: number;
}) {
  const {
    smallerStar,
    biggerStar,
    shouldShowCalculating,
    numberOfStarsWhichFit,
  } = props;

  const [trivia, setTrivia] = useState<string>("");

  // We don't need words for numbers under 1000
  const NUMBER_IN_WORDS_LOWER_CUTOFF = 1000;

  useEffect(() => {
    if (!TRIVIA_ENABLED) {
      return;
    }
    // If the number is over 2 billion, it usually doesn't have a good fact
    const TRIVIA_UPPER_CUTOFF = 2 * 1000 * 1000 * 1000 * 1000;
    const NOT_FOUND = "not-found";
    const TRIVIA_WITH_NOT_FOUND_URL = `http://numbersapi.com/${numberOfStarsWhichFit}/trivia?fragment&default=${NOT_FOUND}`;
    const TRIVIA_WITH_FLOOR_URL = `http://numbersapi.com/${numberOfStarsWhichFit}/trivia?fragment&notfound=floor`;
    fetch(TRIVIA_WITH_NOT_FOUND_URL)
      .then(async (data) => {
        let trivia = await data.text();
        if (trivia === NOT_FOUND) {
          if (numberOfStarsWhichFit > TRIVIA_UPPER_CUTOFF) {
            try {
              const floorTriviaData = await fetch(TRIVIA_WITH_FLOOR_URL);
              const floorTrivia = await floorTriviaData.text();
              setTrivia(`more than ${floorTrivia}`);
            } catch (e) {
              console.warn(
                `Could not fetch floor trivia for the number ${numberOfStarsWhichFit}`
              );
              console.log(e);
              setTrivia("");
            }
          } else {
            setTrivia("");
          }
        } else {
          setTrivia(trivia);
        }
      })
      .catch((e) => {
        console.warn(
          `Could not fetch trivia for the number ${numberOfStarsWhichFit}`
        );
        console.log(e);
        setTrivia("");
      });
  }, [numberOfStarsWhichFit]);

  return (
    <Flex marginTop={10} flexDirection={"column"} alignItems={"center"}>
      {shouldShowCalculating ? (
        <Flex className="animate__animated animate__pulse">
          <Text
            fontSize={"4xl"}
            className="animate__animated animate__fadeOutUp animate__delay-1s"
          >
            Calculating...
          </Text>
        </Flex>
      ) : null}

      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        className="animate__animated animate__jackInTheBox animate__delay-2s"
      >
        <Text fontSize={"4xl"}>{numberOfStarsWhichFit.toLocaleString()}</Text>
        {numberOfStarsWhichFit > NUMBER_IN_WORDS_LOWER_CUTOFF ? (
          <Text fontSize={"sm"} as="i">
            {getNumberInWords(numberOfStarsWhichFit)}
          </Text>
        ) : null}
        {trivia ? (
          <Text fontSize={"sm"} as="i">
            {trivia}
          </Text>
        ) : null}
        <Text>
          <Text as="b">
            {pluralize(smallerStar.name)}
            {` `}
          </Text>
          could fit in{" "}
          {`${
            biggerStar.singularPrefix ? biggerStar.singularPrefix + " " : ""
          }`}
          <Text as="b">{biggerStar.name}</Text>
        </Text>
      </Flex>
    </Flex>
  );
}

enum SearchParams {
  SMALLER_STAR = "how_many",
  BIGGER_STAR = "would_fit_in",
}

export function StarSizeComparison() {
  const [elementRef, height] = useElementHeight();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [shouldShowCalculating, setShouldShowCalculating] = useState(false);

  const updateSearchParams = (paramName: SearchParams, star: Star) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(paramName, star.name);
    setSearchParams(newSearchParams);
  };

  const smallerStar = stars.find(
    (star) => star.name === searchParams.get(SearchParams.SMALLER_STAR)
  );
  const biggerStar = stars.find(
    (star) => star.name === searchParams.get(SearchParams.BIGGER_STAR)
  );

  const bothStarsSelected = smallerStar && biggerStar;

  const numberOfStarsWhichFit = bothStarsSelected
    ? howManyFit(smallerStar, biggerStar)
    : null;

  const hasResult = numberOfStarsWhichFit !== null && bothStarsSelected;

  // Hide the result when stars are changed
  useEffect(() => {
    if (smallerStar && biggerStar) {
      setIsAnswerRevealed(false);
      setShouldShowCalculating(true);
    }
  }, [smallerStar, biggerStar]);

  // If the stars have been changed, and we've hidden the result already, reveal the new result
  useEffect(() => {
    if (!isAnswerRevealed && smallerStar && biggerStar) {
      setIsAnswerRevealed(true);
    }
    const timeout = setTimeout(() => {
      setShouldShowCalculating(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isAnswerRevealed, smallerStar, biggerStar]);

  const largestStar = getBiggerStar(smallerStar, biggerStar);

  return (
    <Flex
      ref={elementRef}
      width={"100%"}
      textAlign="center"
      p={10}
      height={"100vh"}
      flexDirection={"column"}
    >
      <StarSelector
        placeholder="Select a star..."
        prefix="How many"
        onChange={(star) => updateSearchParams(SearchParams.SMALLER_STAR, star)}
        pluralize={true}
        value={smallerStar}
      />
      {smallerStar ? (
        <StarDisplay
          star={smallerStar}
          maxWidth={height / 5}
          largestStar={largestStar}
          marginTop={10}
        />
      ) : null}
      <StarSelector
        placeholder="Select a star..."
        prefix="could fit in"
        suffix="?"
        onChange={(star) => updateSearchParams(SearchParams.BIGGER_STAR, star)}
        value={biggerStar}
      />
      {biggerStar ? (
        <StarDisplay
          star={biggerStar}
          maxWidth={height / 5}
          largestStar={largestStar}
          marginTop={10}
        />
      ) : null}
      {hasResult && isAnswerRevealed ? (
        <StarComparisonResult
          smallerStar={smallerStar}
          biggerStar={biggerStar}
          shouldShowCalculating={shouldShowCalculating}
          numberOfStarsWhichFit={numberOfStarsWhichFit}
        />
      ) : null}
    </Flex>
  );
}
