import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  ButtonGroup,
  Button,
  IconButton,
  Switch,
  FormLabel,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { vibrateThenDo } from "../utils";

const REGULAR_NUMBER_RANGE = {
  min: 10,
  max: 100,
  step: 10,
};

const BIG_NUMBER_RANGE = {
  min: 100,
  max: 1000,
  step: 100,
};

export function CountBy() {
  const [step, setStep] = useState(1);
  const [stepCount, setStepCount] = useState(0);
  const [maxStep, setMaxStep] = useState(10);
  const [isNegativeEnabled, setIsNegativeEnabled] = useState(false);
  const [isBigNumbersEnabled, setisBigNumbersEnabled] = useState(false);

  /**
   * When negatives are disabled, reset the count to 0 if the current value is negative
   */
  useEffect(() => {
    if (!isNegativeEnabled) {
      setStepCount((currentStepCount) =>
        currentStepCount > 0 ? currentStepCount : 0
      );
    }
  }, [isNegativeEnabled, setStepCount]);

  /**
   * Don't allow current step to be greater than max step
   */
  useEffect(() => {
    setStep((currentStep) => (currentStep > maxStep ? maxStep : currentStep));
  }, [setStep, maxStep]);

  /**
   * Only allow max steps within the current range
   */
  useEffect(() => {
    if (!isBigNumbersEnabled) {
      setMaxStep((currentMaxStep) =>
        currentMaxStep > REGULAR_NUMBER_RANGE.max
          ? REGULAR_NUMBER_RANGE.max
          : currentMaxStep
      );
    } else {
      setMaxStep((currentMaxStep) =>
        currentMaxStep < BIG_NUMBER_RANGE.min
          ? BIG_NUMBER_RANGE.min
          : currentMaxStep
      );
    }
  }, [isBigNumbersEnabled, setMaxStep, maxStep]);

  const handleSetMaxStep = (newMaxStep: number) => {
    setMaxStep(newMaxStep);
    // Don't allow the step to be set to a value greater than the max
    if (newMaxStep < step) {
      setStep(newMaxStep);
    }
  };

  const currentNumber = step * stepCount;

  const {
    min: stepSelectorMin,
    max: stepSelectorMax,
    step: stepSelectorStep,
  } = isBigNumbersEnabled ? BIG_NUMBER_RANGE : REGULAR_NUMBER_RANGE;

  return (
    <Flex
      width={"100%"}
      textAlign="center"
      p={10}
      height={"100vh"}
      flexDirection={"column"}
    >
      <Box>
        <Heading fontSize={"4xl"}>Count by {step}'s!</Heading>
      </Box>
      <Flex flex={1}>
        <CountByStepSelector max={maxStep} onChange={(v) => setStep(v)} />
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flex={1}
          flexDirection={"column"}
        >
          <NumberIncrementor
            value={currentNumber}
            onDecrement={(step = 1) =>
              setStepCount((currentStepCount) =>
                currentStepCount - step >= 0 || isNegativeEnabled
                  ? currentStepCount - step
                  : 0
              )
            }
            onIncrement={(step = 1) => {
              setStepCount((currentStepCount) => currentStepCount + step);
            }}
            label={`(${step} x ${stepCount})`}
          />
          <Button
            onTouchStart={() =>
              vibrateThenDo(() => setStepCount(0), [50, 50, 50])
            }
          >
            Reset
          </Button>
        </Flex>
      </Flex>
      <Flex p={5} flexDirection={"row"} justifyContent="space-around">
        <CountBySwitch
          id="isNegativeEnabled"
          label="Negatives"
          value={isNegativeEnabled}
          onToggle={() => setIsNegativeEnabled(!isNegativeEnabled)}
        />
        <CountBySwitch
          id="isBigNumbersEnabled"
          label="Big Numbers"
          value={isBigNumbersEnabled}
          onToggle={() => setisBigNumbersEnabled(!isBigNumbersEnabled)}
        />
      </Flex>
      <CountByMaxStepSelector
        onChange={handleSetMaxStep}
        value={maxStep}
        min={stepSelectorMin}
        max={stepSelectorMax}
        step={stepSelectorStep}
      />
    </Flex>
  );
}

export function CountBySwitch(props: {
  id: string;
  label: string;
  value: boolean;
  onToggle: () => void;
}) {
  const { id, label, value, onToggle } = props;

  return (
    <Flex flexDirection={"column"} alignItems="center" marginTop={2}>
      <Switch
        id={id}
        isChecked={value}
        onChange={() => vibrateThenDo(onToggle)}
      />
      <FormLabel fontSize={"sm"} color="gray.300" htmlFor={id} m={0}>
        {label}
      </FormLabel>
    </Flex>
  );
}

function CountByMaxStepSelector(props: {
  onChange: (newMaxStep: number) => void;
  value: number;
  min: number;
  max: number;
  step: number;
}) {
  const { onChange, min, max, step, value } = props;

  const maxStepMarkers = Array(5)
    .fill(0)
    .map((value, index) => (index + 1) * (step * 2));

  return (
    <Slider
      aria-label="max-step-slider"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
    >
      {maxStepMarkers.map((value) => (
        <SliderMark
          key={value}
          value={value}
          mt="1"
          ml="-2.5"
          fontSize="sm"
          color="gray.300"
        >
          {value}
        </SliderMark>
      ))}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  );
}

function CountByStepSelector(props: {
  max: number;
  onChange: (value: number) => void;
}) {
  const { max, onChange } = props;

  return (
    <Slider
      aria-label="step-slider"
      defaultValue={1}
      min={1}
      max={max}
      onChange={onChange}
      orientation="vertical"
      minH="32"
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb boxSize={8} backgroundColor={"lightblue"} />
    </Slider>
  );
}

enum IncrementAction {
  INCREMENT = "INCREMENT",
  DECREMENT = "DECREMENT",
}

const LONG_PRESS_INCREMENT_INCREASE_THRESHOLD = 25;

export function NumberIncrementor(props: {
  value: number;
  label: string;
  onIncrement: (step?: number) => void;
  onDecrement: (step?: number) => void;
}) {
  const { value, onIncrement, onDecrement, label } = props;

  const isHolding = useRef<boolean>(false);
  const incrementDuringHoldCount = useRef<number>(0);
  const ongoingIncrementAction = useRef<IncrementAction | null>(null);
  const previousLongPressTimeout = useRef<null | NodeJS.Timeout>(null);
  const holdInterval = useRef<null | NodeJS.Timeout>(null);

  const handleLongPress = (incrementAction: IncrementAction) => {
    isHolding.current = true;
    if (previousLongPressTimeout.current) {
      clearInterval(previousLongPressTimeout.current);
      previousLongPressTimeout.current = null;
    }
    previousLongPressTimeout.current = setTimeout(() => {
      if (isHolding.current && !holdInterval.current) {
        ongoingIncrementAction.current = incrementAction;
        holdInterval.current = setInterval(() => {
          if (!isHolding.current && holdInterval.current) {
            clearInterval(holdInterval.current);
            holdInterval.current = null;
            incrementDuringHoldCount.current = 0;
            return;
          }
          incrementDuringHoldCount.current++;
          const step =
            1 +
            2 *
              Math.floor(
                incrementDuringHoldCount.current /
                  LONG_PRESS_INCREMENT_INCREASE_THRESHOLD
              );
          switch (ongoingIncrementAction.current) {
            case IncrementAction.INCREMENT: {
              onIncrement(step);
              break;
            }
            case IncrementAction.DECREMENT: {
              onDecrement(step);
              break;
            }
          }
        }, 50);
      }
    }, 1 * 500);
  };

  const speak = () => {
    const utterance = new SpeechSynthesisUtterance(String(value));
    window.speechSynthesis.speak(utterance);
  };

  return (
    <Flex
      width={"100%"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      flex={1}
    >
      <Text onClick={speak} fontSize={"6xl"} style={{ userSelect: "none" }}>
        {value}
      </Text>
      <ButtonGroup size="lg" paddingTop={5} spacing={10} alignItems={"center"}>
        <IconButton
          aria-label="Count down"
          icon={<MinusIcon />}
          onTouchEnd={() => (isHolding.current = false)}
          onTouchStart={() =>
            vibrateThenDo(() => {
              onDecrement();
              handleLongPress(IncrementAction.DECREMENT);
            })
          }
        />
        <Text fontSize={"sm"} color="gray.300" style={{ userSelect: "none" }}>
          {label}
        </Text>
        <IconButton
          aria-label="Count up"
          icon={<AddIcon />}
          onTouchEnd={() => (isHolding.current = false)}
          onTouchStart={() =>
            vibrateThenDo(() => {
              onIncrement();
              handleLongPress(IncrementAction.INCREMENT);
            })
          }
        />
      </ButtonGroup>
    </Flex>
  );
}
