import React from "react";
import { MathProblem, Problem } from "./MathProblem";
import { MathProblemUtils } from "./utils";

function getFactors(value: number): number[] {
  const factors: number[] = [];
  for (let i = 1; i <= value; i++) {
    if (value % i === 0) {
      factors.push(i);
    }
  }
  return factors;
}

const percentChanceForOneAndSelf = 25;

function generateDivisionProblem(): Problem {
  const numerator = MathProblemUtils.getRandomNumber(0, 100);
  const factors =
    numerator !== 0
      ? getFactors(numerator)
      : [
          MathProblemUtils.getRandomNumber(0, 100),
          MathProblemUtils.getRandomNumber(0, 100),
          MathProblemUtils.getRandomNumber(0, 100),
        ];
  const isPrime = factors.length === 2;
  const includeOneAndSelf =
    isPrime ||
    MathProblemUtils.getRandomNumber(1, 100) <= percentChanceForOneAndSelf;
  const denominator =
    factors[
      MathProblemUtils.getRandomNumber(
        includeOneAndSelf ? 0 : 1,
        includeOneAndSelf ? factors.length - 1 : factors.length - 2
      )
    ];
  const solution = numerator / denominator;
  const options = [
    solution,
    ...MathProblemUtils.getRandomIncorrectSolutions(solution, 10, 2),
  ];
  return {
    question: `${numerator} ÷ ${denominator} = ?`,
    options: MathProblemUtils.shuffleArray(options),
    solution,
  };
}

export function DivisionProblem() {
  return <MathProblem generateProblem={generateDivisionProblem} />;
}
